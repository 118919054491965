<template>
	<el-dialog title="导出搜索结果" :close-on-click-modal="false" width="300px" :visible.sync="is_show_in_page">
		<div style="text-align:center">总数量:{{max}} / 已读取:{{readed}}</div>
	</el-dialog>
</template>
<script>
	export default {
		props:{
			is_show:Number,//是否显示
			rull:Object,//搜索条件
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,
				
				//单次读取数量
				num:100,

				//总数量
				max:0,

				//已读取
				readed:0,

				//上次最后一个id
				id_max:false,

				p:1,
				
				truck_tord_nums:[],

				addr_name:'',
				addr_tel:'',
				addr_info:'',

				//货主编号
				shipper_nums:['221017101235166304','221210151731248553','221211101816428595','230104163922898472','221210151731248553']
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
					this.max=0
					this.readed=0
					this.id_max=false
					this.start()
				}else{
					this.is_show_in_page=false;
				}
			}
		},
		methods: {
			
			//生成csv
			make_csv(){
				var shunxu=[
					{key:"id",cname:"系统id"},
					{key:"tord_item_text",cname:"线路名称"},
					{key:"truck_tord_num",cname:"运单编号"},
					{key:"bl_out_trade_num",cname:"商户运单号"},
					{key:"invoice_num",cname:"开票组编号"},
					{key:"creat_time_text",cname:"建单时间"},
					{key:"invoiced_apply_time",cname:"开票申请时间"},
					{key:"invoiced_time_text",cname:"开票时间"},
					{key:"cargo_info_name",cname:"货主名称"},
					{key:"cargo_info_tel",cname:"货主手机号"},
					{key:"company_name",cname:"抬头"},
					{key:"truck_owner_name2",cname:"车辆所有人"},
					{key:"company_license_code",cname:"税号"},
					{key:"freight_total",cname:"运费"},
					{key:"service_charge",cname:"服务费"},
					{key:"fl",cname:"费率"},
					{key:"payed_total_of_all",cname:"总开票额"},
					{key:"cargo_volume",cname:"货物体积(方)"},
					{key:"cargo_weight",cname:"货物重量(吨)"},
					// {key:"cargo_weight_start",cname:"发货重量(吨)"},
					// {key:"cargo_weight_arrived",cname:"到货重量(吨)"},
					{key:"cargo_name",cname:"货物名称"},
					{key:"driver_tel",cname:"司机电话"},
					{key:"driver_name",cname:"司机姓名"},
					{key:"truck_plate_num",cname:"车牌号"},
                    {key:"truck_type_name",cname:"细分类型"},
					{key:"case_prov",cname:"发货地省份"},
					{key:"case_city",cname:"发货地城市"},
					{key:"case_county",cname:"发货地县区"},
					{key:"case_addr_info",cname:"发货地详细地址"},
					{key:"start_time_text",cname:"发车时间"},
					{key:"aim_prov",cname:"到货地省份"},
					{key:"aim_city",cname:"到货地城市"},
					{key:"aim_county",cname:"到货地县区"},
					{key:"aim_addr_info",cname:"到货地详细地址"},
					{key:"arrived_time_text",cname:"到货时间"},
					{key:"settlement_type_text",cname:"结算方式"},
					{key:"ranks_num",cname:"趟数"},
					{key:"tax_code",cname:"发票代码"},
				    {key:"mark",cname:"备注"},
					{key:"invoice_mark",cname:"发票备注"},
					{key:"addr_name",cname:"收货人姓名"},
					{key:"addr_tel",cname:"收货人电话"},
					{key:"addr_info",cname:"收货人地址"},
					{key:"report_info_text",cname:"上报状态"},
					{key:"notify_text",cname:"上报回调"},
					
					{key:"ready_for_upload_text",cname:"是否军品"},
					{key:"specifications",cname:"规格"},
					{key:"email",cname:"邮箱"},
				],str="";
				for(var a in shunxu){
					str+=shunxu[a].cname+",";
				}
				str+="\n";
				let str_new1
				for(var i=0;i<this.buf.length;i++){
					for(var a in shunxu){
						if(this.buf[i][shunxu[a].key]){
							str_new1= this.buf[i][shunxu[a].key].replaceAll(/[\n\"]/g,' ');
						}
						str+=`${str_new1}\t,`;
					}
					str+='\r\n';
				}

				str = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
				//生成下载链接
				let link = document.createElement('a');
				link.href = str;

				//对下载的文件命名
				link.download = "开票记录.csv";

				//下载
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				
				this.truck_tord_nums=[];
			},
			//数据处理
			init(tord_list,invoice_list,addr_info){
				//预处理
				let new_list=[];
				let symbols = {
					'~':' ',
					';':' ',
					"-":'',
					',':' '
				}
				
				tord_list.forEach((tord_item,index) => {
					if(this.truck_tord_nums.indexOf(tord_item.truck_tord_num)==-1){
						this.truck_tord_nums.push(tord_item.truck_tord_num);
						if(this.addr_name){
							tord_item.addr_name = this.addr_name
							tord_item.addr_tel = this.addr_tel
							tord_item.addr_info =this.addr_info
						}else{
							tord_item.addr_name = addr_info.address_list[0].name
							tord_item.addr_tel = addr_info.address_list[0].tel
							tord_item.addr_info =addr_info.address_list[0].addr_info
						}
					   switch(tord_item.settlement_type){
								case '1':tord_item.settlement_type_text="按吨数";
								    break;
								case '2':
									tord_item.settlement_type_text=`按方数`;
									break;
								case '3':
									tord_item.settlement_type_text=`按趟`;
									break;
						}
						tord_item.ranks_num = '1'
						//成交时间
						tord_item.creat_time_text=this.$my.other.totime(tord_item.creat_time);
						tord_item.email=invoice_list.email
						//开票申请时间
						tord_item.invoiced_apply_time = this.$my.other.totime(invoice_list.creat_time)
						for(const symbol in symbols){
							tord_item.mark = invoice_list.mark?invoice_list.mark.replace(new RegExp(symbol,'g'),symbols[symbol]):tord_item.mark.replace(new RegExp(symbol,'g'),symbols[symbol])
						}
						console.log(tord_item)
						// tord_item.mark =invoice_list.mark?invoice_list.mark:tord_item.mark
						tord_item.invoiced_time_text=tord_item.invoiced_time!=0?this.$my.other.totime(tord_item.invoiced_time):'无';
						/* 装货地其他数据(json) */
						tord_item.case_other_obj=JSON.parse(tord_item.case_other);
						// tord_item.case_addr_info = tord_item.case_other_obj.addr_info
						for(const symbol in symbols){
							tord_item.case_addr_info = tord_item.case_other_obj.addr_info.replace(new RegExp(symbol,'g'),symbols[symbol]);
						}
                        
						// console.log(tord_item.case_addr_info)
						/* 卸货地其他数据(json) */
						tord_item.aim_other_obj=JSON.parse(tord_item.aim_other);
						// tord_item.aim_addr_info = tord_item.aim_other_obj.addr_info
						for(const symbol in symbols){
							tord_item.aim_addr_info = tord_item.aim_other_obj.addr_info.replace(new RegExp(symbol,'g'),symbols[symbol]);
						}
						// tord_item.aim_addr_info = tord_item.aim_other_obj.addr_info.replace(/-//g," ")
						tord_item.driver_name = tord_item.driver_info.name
						tord_item.driver_tel = tord_item.driver_info.tel
						
						tord_item.cargo_info_name = tord_item.shipper_info.name
						tord_item.cargo_info_tel = tord_item.shipper_info.tel
						
						tord_item.company_name = tord_item.shipper_company_info.name
						tord_item.company_license_code = tord_item.shipper_company_info.license_code
						
						tord_item.payed_total_of_all = tord_item.cost_info.payed_total_of_all
						tord_item.freight_total = tord_item.cost_info.freight_total
						tord_item.service_charge = tord_item.cost_info.service_charge
						tord_item.fl = Number(tord_item.cost_info.fl).toFixed(2)

						if(tord_item.bl_name){
							tord_item.tord_item_text = tord_item.bl_name
						}else{
							tord_item.tord_item_text = '无'
						}
						if(this.shipper_nums.indexOf(tord_item.shipper_num) != -1){
							tord_item.specifications = tord_item.truck_plate_num + '-' + tord_item.case_city +'-'+tord_item.aim_city
						}else{
							tord_item.specifications = '无'
						}
                        
				

						if(tord_item.report_info && tord_item.report_info.ssd_error ){
							tord_item.ssd_error_text  = tord_item.report_info.ssd_error
						}else{
							tord_item.ssd_error_text  = '无'
						}
						
						if(tord_item.report_info && tord_item.report_info.ready_for_upload ){
							if(tord_item.report_info.ready_for_upload==3){
								tord_item.ready_for_upload_text = '军品'
							}else{
								tord_item.ready_for_upload_text = '非军品'
							}
						}else{
							tord_item.ready_for_upload_text='无'
						}
						if(tord_item.truck_info.truck_owner_name){
							 tord_item.truck_owner_name2 = tord_item.truck_info.truck_owner_name
						}else{
							 tord_item.truck_owner_name2 = '无'
						}
                       	if(!tord_item.report_info){
							 tord_item.report_info_text = '未同步'
						}else{
							switch(tord_item.report_info.tax_status){
								case '1':tord_item.report_info_text="未上报";break;
								case '2':
									tord_item.report_info_text=`一次已传`;
									break;
								case '3':
									tord_item.report_info_text=`二次已传`;
									break;
								case '4':
								tord_item.report_info_text=`三次已传`;
								break;
							}
						}
						if(tord_item.report_info){
							if(tord_item.report_info.notify){
								// var text = ''
								// tord_item.report_info.notify.forEach(item=>{
								// 	text += item.name+'，'

								// })
								// tord_item.notify_text =  text
								if(tord_item.report_info.notify[0].indicatorStatus == 0){
									tord_item.notify_text = '无异常'
								}else{
									tord_item.notify_text = '有异常'
								}
							}
							// else if(tord_item.report_info.indicatorStatus == 0){
								
							// }else if(tord_item.report_info.indicatorStatus == 1){
							// 	tord_item.notify_text = '有异常'
							// }
							else{
								tord_item.notify_text = '无'
							}
						}else{
							tord_item.notify_text = '无'
						}

						tord_item.truck_type_name = tord_item.truck_info.truck_type_name2
						
						if(tord_item.out_trade_num){
							tord_item.bl_out_trade_num= tord_item.out_trade_num
						}else{
							tord_item.bl_out_trade_num= tord_item.truck_tord_num
						}
						//发车时间
						if(tord_item.start_time>0){//已发车
							tord_item.start_time_text=this.$my.other.totime(tord_item.start_time);
						}else tord_item.start_time_text="未发车";
						
						//到货时间
						if(tord_item.arrived_time>0){//已到货
							tord_item.arrived_time_text=this.$my.other.totime(tord_item.arrived_time);
						}else tord_item.arrived_time_text="未到货";
						
						tord_item.invoice_num=invoice_list.invoice_num;
						
						new_list.push(tord_item);
						
					}else{
						console.log("有重复运单");
						console.log(tord_item.truck_tord_num)
					}
					
					
					
				});
				
				tord_list=new_list;
				
				//置入缓存
				this.buf=this.buf.concat(tord_list)
				this.readed=this.buf.length
				
				
				
				
				//继续读取下一页
				this.p ++
				this.read();
			},
			//读取
			read(){
				var p = this.p
				//读取数据

				this.$my.net.req({
					data:{
						take_over_control:1,
						mod:'truck_tord_real_time',
						ctr:'tord_invoice_list_by_admin',
						is_get_shipper_info:1,
						is_get_shipper_company_info:1,
						is_get_truck_owner_info:1,
						is_get_driver_info:1,
                        need_num: 1,
						for_export:1,
						...this.rull,
						num:1,
						p:p,
					},
					faild:(res)=>{
						
						this.p=1;
						
						//查询失败--前往导出
						this.make_csv()
					},callback:(data)=>{
						if(data.invoice_list.length ==0){//查询失败
							//查询失败--前往导出
							this.p=1;
							this.make_csv();
							return;
						}
	
						// this.p ++
						// if(data.tord_list.length ==0){
						// 	this.p = 1
						// 	this.read()
						// 	return
						// }
						//查询邮寄地址
						this.$my.net.req({
							take_over_control:1,
							data:{
								mod:'truck_tord_real_time',
								ctr:'invoice_address_list',
								user_num:data.invoice_list[0].shipper_info.user_num
							},callback:(res)=>{
								if(res.msg.address_list){
									res.msg.address_list.forEach(item=>{
										if(item.is_default ==1){
											this.addr_name = item.name
											this.addr_tel = item.tel
											this.addr_info = item.addr_info
											
										}
									})
								}else{
									this.addr_name = '无'
									this.addr_tel = '无'
									this.addr_info ='无'
									// this.init(data.tord_list,data.invoice_list[0])
								}
								this.init(data.tord_list,data.invoice_list[0],res.msg)
							}
						})

						
						// //取出最后一个id
						// if(data.tord_list[data.tord_list.length-1]){
      //                      this.id_max=data.tord_list[data.tord_list.length-1].id
						// }

						// //置入缓存
						// this.buf=this.buf.concat(data.tord_list)
						// this.readed=this.buf.length
						
						// //是否继续
						// if(this.readed<this.max){//没有达到最大数量则继续读取

						// 	this.read();
						
						// }else{//达到最大数量则开始导出

						// 	this.make_csv()
						// }
                    }
				})
			},

			//启动
			start(){

				//读取总数量
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_invoice_list_by_admin',
						only_num:1,
						...this.rull,
					},
					callback:(data)=>{
						//如果总数量为0
						if(data.max==0){
							this.$my.other.msg({
								type:"warning",
								str:'该条件下数据为空'
							});
							return;
						
						//置入总数量
						}else this.max=data.max;

						//已读取数据
						this.buf=[];

						//开始读取
						this.read();
					}
				})
			},
		}
	}
</script>